import React, { useState ,useEffect} from 'react';
import LogiImg from '../assets/images/chatbot1.png';
import DLogoBlue from '../assets/images/logo-blue.png';
import { post } from '../lib/HttpServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Login = () => {
const [username,setUsername] = useState('');
const [password,setPassword] = useState('');
const [validLogin,setvalidLogin] = useState(false);
const [Loading,setloading] = useState(false)
const navigate = useNavigate();



useEffect(() => {
  // setloading(true);
  if (username && password) {
    const fakeToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxMjM0NTY3ODkwIiwidXNlclR5cGUiOiJhZG1pbiIsImlhdCI6MTUxNjIzOTAyMn0.4AsqKLUmtgAsU3vVV8T0-HdqKCMGIkH7osQJHV5wO7U'
    
    post('login', {password:password,email:username})
    .then((response) => {
      toast.success("Login successfull ", {
        autoClose: 3000, 
      });
      const access_token = response.data.access_token
      const refresh_token = response.data.refresh_token
      const name = response.data.name

      localStorage.setItem('ChatData', access_token);
     localStorage.setItem('refresh_token', refresh_token);
     localStorage.setItem('name',name)
    navigate('/dashboard'); 
    })
    .catch((error) => {
      if (error.response.status === 500) {
      toast.error("Internal Server Error (500). Please try again later.", {
        autoClose: 2000, 
      });
    }
    else{
      toast.error(error.response.data.msg, {
        autoClose: 2000, 
      });
    }
    });
    

    // Redirect to the dashboard after login
  } 

}, [validLogin]);



const onClickLogin = (e) => {
  e.preventDefault(); // Prevent default form submission behavior
  if (!username || !password) {
    toast.error("Username and password are required", { autoClose: 3000 });
    
  }
  console.log("Proceeding with login for", username);
  setvalidLogin(!validLogin)
};

const usernameChange = (e) => {
  const temp_username = e.target.value;
  console.log(temp_username)
  const hasWhitespace = /\s/.test(temp_username);
  // Prevent input if it contains whitespace or exceeds the length limit
  if (!hasWhitespace && temp_username.length <= 32) {
    setUsername(temp_username);
  }
  if (hasWhitespace) {
    toast.error("Whitespace is not allowed.", {
      autoClose: 2000, // Automatically close the toast after 3 seconds
    });
  }
  if (temp_username.length > 32) {
    toast.error("Username cannot be longer than 32 characters.", {
      autoClose: 2000, // Automatically close the toast after 3 seconds
    });
  }
};

const passwordChange = (e) => {
  const temp_password = e.target.value;
  const hasWhitespace = /\s/.test(temp_password);
  // Prevent input if it contains whitespace or exceeds the length limit
  if (!hasWhitespace && temp_password.length <= 32) {
    setPassword(temp_password);
  }
  if (hasWhitespace) {
    toast.error("Whitespace is not allowed.", {
      autoClose: 2000, // Automatically close the toast after 3 seconds
    });
  }
  if (temp_password.length > 32) {
    toast.error("Password cannot be longer than 32 characters.", {
      autoClose: 2000, // Automatically close the toast after 3 seconds
    });
  }
};


    return (
        <div>
          
            <section className="space-main">
     <div className="container">
       <div className="row vh-100">
         <div className="col-lg-8 m-auto">
            <div className="row align-items-center login-box">
              <div className="col-lg-6 text-center">
              <div className="col-lg-12 mb-4 text-center">
                <img src={DLogoBlue} className="img-fluid" alt="" style={{width: '180px'}} /> 
                </div>
                <img src={LogiImg} alt="Logo" className="login-logo-img" />
              </div>
              
              <div className="form-section col-lg-6">
              <div className="col-lg-12 mb-4">
                <h3>
                   Sign in
                </h3>
              </div>
                <div className="form-group mb-2">
                  <label for="exampleInputEmail1">Username or Email ID</label>
                  <input type="text" value={username} maxLength={32}  onChange={usernameChange} className="form-control" id="exampInputEmail1" aria-describedby="emailHelp" placeholder=""  required/>
                </div>
                <div className="form-group mb-2">
                  <label for="exampleInputPassword1">Password</label>
                  <input type="password" onChange={passwordChange} value={password} className="form-control" id="exampleInputPassword1" placeholder="" required />
                </div>
                {/* <button type="submit" className="btn btn-primary btn-block w-100 my-4" onclick="location.href='dashboard.html';">Sign up</button> */}
                <button type="submit" onClick={onClickLogin}  className="btn btn-outline-primary btn-block w-100 mb-4">Sign in</button>
              </div>
            </div>
            <div className="text-center py-3">
              <a href="" className="text-white">www.dcubeai.com</a>
            </div>
         </div>
       </div>
     </div>
   </section>
        </div>
    )
}

export default Login;
