import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Plus from '../assets/images/plus.svg';
import CrossImg from '../assets/images/cross.png';
import EditableBox from '../components/EditableBox';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import axios from 'axios';
import { post,get } from '../lib/HttpServices';
import { toast } from 'react-toastify';

const ContentManagement = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [showSection, setShowSection] = useState(false);
    const [keywords, setKeywords] = useState('');
    const [question, setQuestion] = useState('');
    const [totalQuestion, setTotalQuestion] = useState(0);
    const [answer, setAnswer] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [website_url, setWebsite_url] = useState('');
    const [template, setTemplate] = useState('Image');
    const [editTemplate, setEditTemplate] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [templateOptions, setTemplateOptions] = useState([]);
    // State variable to trigger API call
    const [submitForm, setSubmitForm] = useState(false);
    const [is_published, setIs_published] = useState(false);
    const [editmode, setEditmode] = useState(false);
    const [selectedOptionlink, setSelectedOptionlink] = useState('');
    const [templateButton, setTemplateButton] = useState('None');
    const [typeActionButton, setTypeActionButton] = useState('Call Phone Number');
    const [typeCountryList, setTypeCountryList] = useState('');
    const [typeURLText, setTypeURLText] = useState('');
    const [buttonFields, setButtonFields] = useState([{ id: Date.now(), value: '' }]);
    const [templateLinkOptionList,settemplateLinkOptionlist] = useState([])
    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    const toggleSidebar = () => {
        setIsSidebarOpen(prevState => !prevState);
    };

    const handleQuestionUpdate = (updatedTotal,published_Total) => {
        setTotalQuestion(published_Total);
      };

      const EditModeUpdate = (bool) => {
        setEditmode(bool);
      };
      
    const handleButtonClick = () => {
        if(editmode === false){
        setShowSection(true);
        }
    };

    const handleCancelClick = () => {
        setShowSection(false);
        clearForm();
    };

    const clearForm = () => {
        setKeywords('');
        setQuestion('');
        setAnswer('');
        setButtonText('');
        setPhoneNumber('')
        setWebsite_url('')
        setTemplate('Image');
        setErrorMessage('');
        setSelectedOptionlink('');
        
    };
       // Function to add a new button field
    const addButtonField = () => {
        setButtonFields([...buttonFields, { id: Date.now(), value: '' }]);
    };

    // Function to remove a button field by its id
    const removeButtonField = (id) => {
        setButtonFields(buttonFields.filter(field => field.id !== id));
    };
    const options = [
        { value: 'Image', label: 'Image' },
        { value: 'Button', label: 'Button' },
        { value: 'Carousel', label: 'Carousel' },
        { value: 'Document', label: 'Document' },
        { value: 'Flow', label: 'Flow' },
        { value: 'Text', label: 'Text' },
    ];
    const optionsButton = [
        { value: 'Call To Action', label: 'Call To Action' },
        { value: 'Quick Reply', label: 'Quick Reply' },
        { value: 'None', label: 'None' },
    ];
    const optionsTypeAction = [
        { value: 'Call Phone Number', label: 'Call Phone Number' },
        { value: 'Visit Website', label: 'Visit Website' },
    ];
    const optionsCountryList = [
        { value: '+ 91 ', label: '+ 91' },
        { value: '+ 88', label: '+ 88' },
    ];
    const optionsURLText = [
        { value: 'Static ', label: 'Static' },
        { value: 'Dynamic', label: 'Dynamic' },
    ];
    const handlePublishClick = () => {
        // Basic validation to ensure all fields are filled
        if (!keywords || !question || !answer || !template) {
            setErrorMessage('All fields are required');
            return;
        }
        else{
        // Set state to trigger form submission
        setIs_published(true)
        setSubmitForm(true);
        }
    };

    const handleSaveDraftClick = () => {
        // Basic validation to ensure all fields are filled
        if (!keywords || !question || !answer || !template) {
            setErrorMessage('All fields are required');
            return;
        }
        else{
        // Set state to trigger form submission
        setIs_published(false)
        setSubmitForm(true);
        }
    };



    useEffect(() => {
        if (submitForm) {
            const formData = new FormData();
            formData.append('keyword', keywords);
            formData.append('question', question);
            formData.append('answer', answer);
            formData.append('templates', template);
            formData.append('created_by', "Admin");
            formData.append('is_published', is_published);
            if(template !== 'Text'){
                formData.append('template_link', selectedOptionlink);
            }
            else{
                formData.append('template_link', '');
            }
            // Append values from dropdowns, ensuring only the selected value is added
            // if (template ==='Button'){
            // formData.append('buttons', templateButton || '');  // Use .value if optionsButton is selected
            // formData.append('type_of_action', typeActionButton || '');
            // formData.append('button_text', buttonText || '');
            // formData.append('country_code', typeCountryList || '');
            // formData.append('phone_number', phoneNumber || '');
            // formData.append('url_text', optionsURLText?.value || '');
            // formData.append('website_url', website_url || '');
            
            //  // Handle Quick Reply button text array format
            //     if (templateButton === 'Quick Reply') {
            //         const formattedButtons = buttonFields.map(field => ({ value: field.value }));
            //         formData.append('quick_reply_buttons_texts', JSON.stringify(formattedButtons));
            //     }
            // }

            if (template === 'Button') {
                // Create an object to hold all button-related fields
                const buttonData = {
                    templateButton: templateButton || '',
                    type_of_action: typeActionButton || '',
                    button_text: buttonText || '',
                    country_code: typeCountryList || '',
                    phone_number: phoneNumber || '',
                    url_text: optionsURLText?.value || '',
                    website_url: website_url || ''
                };
            
                // Handle Quick Reply button text array format
                if (templateButton === 'Quick Reply') {
                    buttonData.quick_reply_buttons_texts = buttonFields.map(field => ({ value: field.value }));
                }
            
                // Append the button data under the key 'button_data'
                formData.append('button_data', JSON.stringify(buttonData));
            }

            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
              }
            post('add_question', formData)
            // post('', formData)
        .then((response) => {
          toast.success("Update successful! Your changes have been saved.", {
            autoClose: 3000, 
          });
          setShowSection(false);
        clearForm();
        })
        .catch((error) => {
          toast.error("Internal Server Error (500). Please try again later.", {
            autoClose: 2000, 
          });
        });
        }
        
        setSubmitForm(false)
    }, [submitForm]);

    useEffect(() => {
        get(`get_template_lists `)
        .then((res) => {
          // console.log("Data:", res.data.data); // Log the data field explicitl
          const temp_list = res.data.templates
          const formattedOptions = temp_list.map((template) => ({
            value: capitalize(template), // Capitalize the first letter
            label: capitalize(template),
        }));
         setTemplateOptions(formattedOptions)

        })
        .catch((error) => {
          if (error.response) {
          console.log("errr in dashbord_leads");
          }
        });

        get(`get_media_files`)
        .then((res) => {
            const templinks_data = res.data.data.media_files_list
            const links_data = templinks_data.map(item => item.link);
            const updated_links_data = [{ label: "Select a Link", value: "" }, ...links_data.map(link => ({ label: link, value: link }))];
            settemplateLinkOptionlist(updated_links_data)
        })
        .catch((error) => {
          if (error.response) {
          console.log("errr in get_questions");
          }
        });
        
    }, []);

    return (
        <div>
            <Sidebar isSidebarOpen={isSidebarOpen} />
            <section className="home-section">
                <Header toggleSidebar={toggleSidebar} />
                <div className="project-task">
                    <div className="inside-box p-4">
                        {showSection && (
                            <div className="add-new-ques mb-5">
                                <div>
                                    <h6 className="my-2">Keywords</h6>
                                    <input
                                        type="text"
                                        className="w-25 form-control mb-2"
                                        placeholder="Enter Keywords here"
                                        value={keywords}
                                        onChange={(e) => setKeywords(e.target.value)}
                                    />
                                </div>
                                <h6 className="my-2">Question</h6>
                                <div>
                                    <input
                                        type="text"
                                        className="w-100 form-control mb-2"
                                        placeholder="Ask your question here"
                                        value={question}
                                        onChange={(e) => setQuestion(e.target.value)}
                                    />
                                    <h6 className="my-2">Answer</h6>
                                    <textarea
                                        className="w-100"
                                        rows="3"
                                        value={answer}
                                        onChange={(e) => setAnswer(e.target.value)}
                                    />
                                    <div className="d-flex align-items-center ">
                                    <div className="w-25">
                                        <h6 className="my-2">Template :</h6>
                                        <Dropdown
                                            options={templateOptions || options}
                                            onChange={(option) => setTemplate(option.value)}
                                            value={template}
                                            placeholder="Select an option"
                                        />
                                    </div>
                                    {template !=='Text' && template !=='Button' && (<>  
                                    <div className=" ms-4 w-100">
                                        <h6 className="my-2">Link :</h6>
                                        <Dropdown
                                            options={templateLinkOptionList}
                                            onChange={(option) => setSelectedOptionlink(option.value)}
                                            value={selectedOptionlink}
                                            placeholder="Select a Link"
                                            className="w-100"
                                        />
                                        {/* <select
                                            value={selectedOption}
                                            onChange={(e) => setSelectedOption(e.target.value)}
                                        >
                                            {optionsUploads.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                            ))}
                                        </select> */}
                                    </div>
                                    </>)}


                                    </div>
                                    {/* select Button  */}
                                    {template ==='Button' &&
                                    <div className="w-100 mt-4">
                                        <h5>Buttons</h5>
                                        <div className="w-25">
                                        <Dropdown
                                            options={optionsButton}
                                            onChange={(option) => setTemplateButton(option.value)}
                                            value={templateButton}
                                            placeholder="None"
                                        />
                                        </div>
                                    </div>
                                    }
                                    {/*  */}
                                    {templateButton === 'Call To Action' && template ==='Button' && (
                                    <div className="w-100 d-flex align-items-center justify-content-between mt-3" style={{gap:'15px'}}>
                                        <div className="col">
                                        <h6 className="my-2">Type of Action :</h6>
                                        <Dropdown
                                            options={optionsTypeAction}
                                            onChange={(option) => setTypeActionButton(option.value)}
                                            value={typeActionButton}
                                            placeholder="Call Phone Number"
                                            className="w-100"
                                        />
                                        </div>
                                        <div  className="col">
                                          <h6 className="my-2">Button Text :</h6>
                                          <input type="text" className="form-control" 
                                          value={buttonText}
                                          onChange={(e) => setButtonText(e.target.value)}
                                           />
                                        </div>
                                        {typeActionButton === 'Call Phone Number' && (
                                        <div className="col">
                                          <h6 className="my-2">Country Code:</h6>
                                          <Dropdown
                                            options={optionsCountryList}
                                            onChange={(option) => setTypeCountryList(option.value)}
                                            value={typeCountryList}
                                            placeholder="Country"
                                            className="w-100"
                                        />
                                        </div>
                                          )}
                                          {typeActionButton === 'Visit Website' && (
                                        <div className="col">
                                        <h6 className="my-2">URL Text :</h6>
                                          <Dropdown
                                            options={optionsURLText}
                                            onChange={(option) => setTypeURLText(option.value)}
                                            value={typeURLText}
                                            placeholder="URL Text"
                                            className="w-100"
                                        />
                                        </div>
                                         )}
                                          {typeActionButton === 'Call Phone Number' && (
                                        <div className="col">
                                          <h6 className="my-2">Phone Number :</h6>
                                          <input type="number" className="form-control" 
                                           value={phoneNumber}
                                           onChange={(e) => setPhoneNumber(e.target.value)}
                                           />
                                        </div>
                                          )}
                                        {typeActionButton === 'Visit Website' && (
                                        <div className="col">
                                          <h6 className="my-2">Website URL :</h6>
                                          <input type="text" className="form-control" 
                                          value={website_url}
                                          onChange={(e) => setWebsite_url(e.target.value)}
                                           />
                                        </div>
                                          )}
                                    </div>
                                    )}
                                    {templateButton === 'Quick Reply' && template ==='Button' && (
                                    <div className="col mt-3">
                                        <h6 className="my-2">Button Text :</h6>
                                        {/* <div className="d-flex align-items-center w-25 mb-4">
                                        <input type="text" className="form-control" value="" />
                                        <img src={CrossImg} className="ms-4" style={{width:"30px" }} />
                                        </div> */}
                                           {buttonFields.map((field, index) => (
                                            <div key={field.id} className="d-flex align-items-center w-25 mb-4">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        const updatedFields = buttonFields.map(f =>
                                                            f.id === field.id ? { ...f, value: e.target.value } : f
                                                        );
                                                        setButtonFields(updatedFields);
                                                    }}
                                                />
                                                <img
                                                    src={CrossImg}
                                                    className="ms-4"
                                                    style={{ width: "30px", cursor: "pointer" }}
                                                    onClick={() => removeButtonField(field.id)}
                                                    alt="Remove"
                                                />
                                            </div>
                                        ))}
                                        <button className="btn btn-success" onClick={addButtonField}>
                                        <img
                                            src={Plus}
                                            alt="Plus"
                                            style={{ width: '15px', marginRight: '15px' }}
                                        />
                                        Add Another Button
                                    </button>
                                    </div>
                                    )}  
                                    {/* select Button */}
                                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                                    <div className="d-flex align-items-center mt-3">
                                    <button className="btn btn-primary me-2" onClick={handleSaveDraftClick}>
                                            Save as Draft
                                        </button>
                                        <button className="btn btn-primary me-2" onClick={handlePublishClick}>
                                            Publish
                                        </button>
                                        <button
                                            className="btn btn-outline-primary me-2"
                                            onClick={handleCancelClick}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex  flex-column">
                           
                            {!showSection && ( <>
                                <h4 className="mb-0 me-3 mb-3 ">Question List</h4>
                                {/* <div
                                    style={{
                                        background: '#A4ABC8',
                                        padding: '3px 14px',
                                        color: '#fff',
                                        borderRadius: '16px',
                                        fontSize: '14px',
                                    }}
                                >
                                    {totalQuestion}
                                </div> */}


                                {/* <div className='d-flex align-items-center'>
                                <p className="mb-0 me-3">Total Questions Published </p>
                                <div
                                    style={{
                                        background: '#A4ABC8',
                                        padding: '3px 14px',
                                        color: '#fff',
                                        borderRadius: '16px',
                                        fontSize: '14px',
                                    }}
                                >
                                    {totalQuestion}
                                </div>
                                </div> */}

                                </>
                               
                            )}
                            </div>
                            {!showSection && (           
                            <div>

                            <button
                                className="btn-primary btn"
                                onClick={handleButtonClick}
                                style={{ padding: '8px 23px' }}
                            >
                                <img
                                    src={Plus}
                                    alt="Plus"
                                    style={{ width: '15px', marginRight: '15px' }}
                                />
                                Add Questions
                            </button>
                            {/* <button
                                className="train-btn ms-4"
                                style={{ padding: '8px 23px' }}
                            >
                                Train
                                <span className="train-count">5</span>
                            </button> */}
                            </div>
                            )} 
                        </div>
                           {!showSection && (           
                        <EditableBox onQuestionCountUpdate={handleQuestionUpdate} refresh={submitForm} template_OPtions={templateOptions} onEditmodeChange={EditModeUpdate} template_link_options = {templateLinkOptionList}/>
                        )}   
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ContentManagement;
