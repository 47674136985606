import React from 'react';
import { Navigate } from 'react-router-dom';

// ProtectedRoute component to restrict access based on JWT token
const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('ChatData'); // Retrieve the token from localStorage

  // If token is not available, redirect to login page
  if (!token) {
    return <Navigate to="/" replace />;
  }

  // If token exists, allow access to the requested page
  return children;
};

export default ProtectedRoute;