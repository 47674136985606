import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import DataTable from 'react-data-table-component';
import DummyImg from '../assets/images/dummy-img.png';
import { Modal } from 'antd';
import { Input } from "antd";
import SearchImg from '../assets/images/search.svg';
import FilterByDate from '../components/filterByDate';
import { useNavigate } from 'react-router-dom';
import { get,getWithHeader } from '../lib/HttpServices'; 
import axios from 'axios';


const Appointments = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const toggleSidebar = () => {
      setIsSidebarOpen(prevState => !prevState);
    };
    const [modal2Open, setModal2Open] = useState(false);
    const [fiterText,setfiterText]= useState('');
    const onChangeFilter = async (e) => {
      console.log(e);
    };
    const[filterCustomerData,setfilterCustomerData] = useState([]);
    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
    const[leadCustomerData,setleadCustomerData] = useState([]);
    const[imageId,setImageId]= useState("")
    const[isLoading,setIsLoading] =useState(false);
    const[modalOpen,setModalOpen] = useState(false)
    const [imageSrc, setImageSrc] = useState(null);

    // const data = [
    //   { id: 1, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', image: 'https://via.placeholder.com/150' },
    //   { id: 2, date: '22/10/2023', mobile: '9547456544', name: 'John', image: 'https://via.placeholder.com/150' },
    //   { id: 3, date: '22/10/2023', mobile: '9547456544', name: 'Mary', image: 'https://via.placeholder.com/150' },
    //   { id: 4, date: '22/10/2023', mobile: '9547456544', name: 'Alex', image: 'https://via.placeholder.com/150' },
    //   // Add more data as required
    // ];


    // const sortByDate = (data) => {
    //   return data.sort((a, b) => {
    //     // Function to convert "20 Jan 2004, 8:00PM" into a Date object
    //     const parseDate = (dateString) => {
    //       const [datePart, timePart] = dateString.split(", ");
    //       const [day, month, year] = datePart.split(" ");
    //       const time = timePart.replace("PM", " PM").replace("AM", " AM"); // Ensure correct time format
    
    //       // Months map for converting "Jan", "Feb", etc., into numbers
    //       const months = {
    //         Jan: 0,
    //         Feb: 1,
    //         Mar: 2,
    //         Apr: 3,
    //         May: 4,
    //         Jun: 5,
    //         Jul: 6,
    //         Aug: 7,
    //         Sep: 8,
    //         Oct: 9,
    //         Nov: 10,
    //         Dec: 11
    //       };
    
    //       return new Date(`${year}-${months[month] + 1}-${day} ${time}`);
    //     };
    
    //     // Convert both dates and compare them
    //     const dateA = parseDate(a.date);
    //     const dateB = parseDate(b.date);
    
    //     return dateB - dateA; // Sort in descending order (latest date first)
    //   });
    // };
    

    
    const columns = [
      {
        name: <div>Date</div>,
        selector: (row, i) => row.timestamp,
      },
      {
        name: <div>Mobile Number</div>,
        selector: (row, i) => row.phone_number,
      },
      {
        name: <div>Name</div>,
        selector: (row, i) => row.user_name,
        },
    
      {
        name: 'Image',
        selector: row => (
          <button
            onClick={() => handleViewImage(row.whatsapp_image_id)}
            className="btn custom-btn-sec"
          >
            View
          </button>
        ),
      },
    ];





  useEffect(() => {
    get(`get_appointments`)
      .then((res) => {
        console.log("res data is", res);
        const temp_appointmant_data = res.data
        console.log("temp_appointmant_data is", temp_appointmant_data)
        setleadCustomerData(temp_appointmant_data)
      })
   
  }, [dateRange]);


  
  useEffect(() => {
    if (imageId !== "") {
      setIsLoading(true)
      getWithHeader(`fetch_image_data/${imageId}`, { responseType: 'blob' }) // Set responseType to 'blob' for binary data
        .then((res) => {
          const blob = new Blob([res.data], { type: 'image/jpeg' }); // Adjust type based on image format
          const imageBlobUrl = URL.createObjectURL(blob);
          setImageSrc(imageBlobUrl);
        })
        .catch((error) => console.error('Error fetching image:', error)).finally(()=>{
          setIsLoading(false)
        });
    }
  }, [imageId]);

  useEffect(() => {
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [imageSrc]);

  const handleViewImage = (id) => {
    setImageId(id);     
    setModalOpen(true);  
  };



  return (
    <div>
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <section className="home-section">
          <Header toggleSidebar={toggleSidebar} />
          <div className="project-task">
              <div className="inside-box p-4">
              <div className="d-flex align-items-center justify-content-end mb-4">
              <form action="">
                  <div className="bg-light rounded rounded-pill shadow-sm me-3" style={{ border: '1px solid #DEDEDE', minWidth: '370px' 
                  }}>
                      <div className="input-group search-space">
                      <Input type="search" placeholder="Search with Name,Location or Date" value={fiterText}  onChange={onChangeFilter} aria-describedby="button-addon1" className=" ms-2 form-control rounded-pill border-0 bg-light"  />
                      <div className="input-group-append">
                          <button id="button-addon1" type="submit" className="btn btn-link text-primary border-0">
                          <img src={SearchImg} alt="" className="" /> 
                              </button>
                      </div>
                      </div>
                  </div>
                  </form>
                  </div>
                <DataTable
                  columns={columns}
                  data={leadCustomerData}
                  // data={data}
                  pagination
                  className="table-style"
                />
              </div>
          </div>
      </section>
      <Modal
        title="Uploaded Image"
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        {imageSrc && !isLoading ? (
          <img
            src={imageSrc}
            alt="Uploaded"
            style={{ width: '100%', height: '500px', objectFit: 'contain' }}
          />
        ) : (
          <p>Loading image...</p>
        )}
      </Modal>
    </div>
  );
};

export default Appointments;
