import React, { useState, useEffect } from "react";
import Edit from '../assets/images/edit.svg';
import viewIconCount from '../assets/images/view-icon.svg';
import { post,get } from '../lib/HttpServices';
import { toast } from 'react-toastify';
import Dropdown from 'react-dropdown';
import { Tabs } from 'antd';
import DataTable from 'react-data-table-component';
import Plus from '../assets/images/plus.svg';
import UploadIcon from '../assets/images/upload.png';
import { useNavigate } from 'react-router-dom';
import { parse, format } from 'date-fns';



const editableData = {
    total_length: 6,
    Question_list: [
      {
        id: "1",
        Question: "What programming languages do you know?",
        Ans: "I took time to research whether I should learn Python or R first. I knew that I would end up learning both, especially since I’m interested in moving into statistical analysis at some point. But at the start, Python seemed like the better language to help me get started because it’s so popular. I enjoyed having access to more resources while I learned it.",
         "template": "image",
       },
      {
        id: "2",
        Question: "What is your favorite framework?",
        Ans: "I enjoy working with React for front-end development. Its component-based architecture makes it easy to manage and scale applications. For the backend, I prefer using Django with Python due to its robust and secure nature.",
         "template": "button",
         },
      {
        id: "3",
        Question: "What projects have you worked on?",
        Ans: "I have worked on multiple full-stack web applications, ranging from e-commerce platforms to data-driven dashboards. One of the more complex projects involved integrating AI models to automate customer support within a web app.",
         "template": "courosel",
         },
      {
        id: "4",
        Question: "What are your future career goals?",
        Ans: "My goal is to specialize in AI and machine learning technologies while also becoming proficient in cloud architecture and DevOps, eventually moving towards a leadership role in tech.",
         "template": "image",
           },
      
    ]
  };


  const options = [
    { value: 'Image', label: 'Image' },
    { value: 'Button', label: 'Button' },
    { value: 'Carousel', label: 'Carousel' },
    { value: 'Document', label: 'Document' },
    { value: 'Flow', label: 'Flow' },
    { value: 'Text', label: 'Text' },
];

const EditableBox = ({ onQuestionCountUpdate ,refresh,template_OPtions ,onEditmodeChange ,template_link_options}) => {
  const [data, setData] = useState([]);
  const [editedId, setEditedId] = useState("");
  const [editedQuestion, setEditedQuestion] = useState("");
  const [editedAnswer, setEditedAnswer] = useState("");
//   const [totalQuestion, setTotalQuestion] = useState('');
  const [editedTemplate, seteditedTemplate] = useState("");
  const [editedTemplateLink, seteditedTemplateLink] = useState("");
  const [editedkeywords, seteditedkeywords] = useState("");
  const [editformSubmit, seteditformSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploaderrorMessage, setUploadErrorMessage] = useState('');
  const [is_published,setIs_published] = useState(false)
  const [draftData, setdraftData] = useState([]);
  const [publishedData, setpublishedData] = useState([]);
  const [reload ,setReload] = useState(false)
  const { TabPane } = Tabs;
  const [editmode,setEditMode] = useState(false);
  const [showUploadform,setshowUploadform] = useState(false)
  const [folderPathlist, setfolderPathlist] = useState([]);
  const [documentName, setdocumentName] = useState('')
  const [selectedFile, setSelectedFile] = useState(null);
  const [upload ,setUpload] = useState(false);
  const [uploadedDataList,setuploadedDataList] = useState([])
//    const[templateOptions,setTemplateOptions] = useState([]);



   const navigate = useNavigate();
  // Sample data
//   const dataLink = [
//     { id: 1, title: 'Anwar', link: 'http://localhost:3000/content-management#' },
//     { id: 2, title: 'Anwar', link: 'http://localhost:3000/content-management#' },
//     { id: 3, title: 'Anwar', link: 'http://localhost:3000/content-management#' },
//     { id: 4, title: 'Anwar', link: 'http://localhost:3000/content-management#' },
//     { id: 5, title: 'Anwar', link: 'http://localhost:3000/content-management#' },
//   ];
  
  const [selectedOption, setSelectedOption] = useState('');
//   const folderPath = [
//     { value: '', label: 'Select a Path' },
//     { value: 'http://localhost:3000/content-management#', label: 'http://localhost:3000/content-management#' },
//     { value: 'http://localhost:3000/content-management#', label: 'http://localhost:3000/content-management#' },
//     { value: 'http://localhost:3000/content-management#', label: 'http://localhost:3000/content-management#' }
// ];
  // Define the columns
  const columns = [
    // {
    //     name: 'Date',
    //     selector: row => row.date_time,
    //     sortable: true,
    //   },
    {
      name: 'Date',
      selector: (row) => parse(row.date_time, 'dd MMM yyyy, hh:mm a', new Date()),  // Parse based on the date format
      // cell: (row) => <div>{format(parse(row.date_time, 'dd MMM yyyy, hh:mm a', new Date()), 'PPpp')}</div>, // Reformat if needed
      cell: (row) => <div>{row.date_time}</div>,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.file_name,
      sortable: true,
    },
    {
      name: 'Link',
      selector: row => row.link,
      sortable: true,
    },
  ];

 

  // Simulate fetching data
  useEffect(() => {
    setData([])
    setpublishedData([])
    setdraftData([])
    if(!refresh ){
        get(`get_questions`)
        .then((res) => {
            const questions = res.data.data.Question_list;
            setData(questions);
           
            const published = questions.filter((item) => item.is_published);
            const draft = questions.filter((item) => !item.is_published);
            setpublishedData(published);
            setdraftData(draft);
            onQuestionCountUpdate(res.data.data.total_length,published.length)
        })
        .catch((error) => {
          if (error.response) {
          console.log("errr in get_questions");
          }
        });


   
    }
  }, [refresh,reload]);
  
  useEffect(() => {
    if(upload === false){
    get(`media_folder_list`)
    .then((res) => {
       setfolderPathlist(res.data)
    })
    .catch((error) => {
      if (error.response) {
      console.log("errr in get_questions");
      }
    });

    get(`get_media_files`)
    .then((res) => {
      setuploadedDataList([])
      const temp_media_data = res.data.data.media_files_list || []
       setuploadedDataList(temp_media_data)
    })
    .catch((error) => {
      if (error.response) {
      console.log("errr in get_questions");
      }
    });
    console.log(template_link_options)
  }
}, [upload]);


useEffect(() => {
    if(upload ){
        const formData = new FormData();
        formData.append('names', documentName);
        formData.append('server_path', selectedOption);
        formData.append('file', selectedFile);

        post('upload_media', formData)
    .then((response) => {
    toast.success("Upload successful!", {
        autoClose: 3000, 
      });
      setdocumentName('');
    setSelectedFile(null);
    setSelectedOption('');
    setshowUploadform(false);
    setUpload(false);
    console.log("upload success before navigate");
      navigate('/content-management');
      console.log("upload success  after navigate");
    })
    .catch((error) => {
      toast.error("Internal Server Error (500). Please try again later.", {
        autoClose: 2000, 
      });
    }); 
    }
    setdocumentName('');
    setSelectedFile(null);
    setSelectedOption('')
    setshowUploadform(false)
    setUpload(false)
}, [upload]);



const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    setSelectedFile(file); // Store the file in state
  };


  const showUploadformclick= () => {
    setdocumentName('');
    setSelectedFile(null);
    setSelectedOption('')
    setshowUploadform(false)
    setshowUploadform(true);
};

const onDocumentnameChange=(e)=>{
    const val = e.target.value
    setdocumentName(val)
}
const cancelUploadformclick= () => {
    setshowUploadform(false);
};
const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

  const handleEditClick = (id, question, answer,template,keyword,template_link) => {
    onEditmodeChange(true)
    setEditMode(true);
    setEditedId(id);
    setEditedQuestion(question);
    setEditedAnswer(answer);
    
    const temp_template = capitalize(template)
    seteditedTemplate(temp_template);
    seteditedkeywords(keyword)
    seteditedTemplateLink(template_link)
    
  };
  const clearForm = () => {
    seteditedkeywords('');
    setEditedQuestion('');
    setEditedAnswer('');
    setEditedAnswer('Image');
    setErrorMessage('');
    seteditedTemplate('');
    seteditedTemplateLink('');
};

  const handleSaveClick = () => {
    setIs_published(false)
    const updatedData = {
      id: editedId,
      Question: editedQuestion,
      Ans: editedAnswer,
      template:editedTemplate,
      keyword:editedkeywords,
    };
    if (!editedkeywords || !editedQuestion || !editedAnswer || !editedTemplate) {
        setErrorMessage('All fields are required');
        return;
    }
    else{
    // setEditedId(""); // Exit edit mode
    seteditformSubmit(true)
    }
  };



  const handlePublishClick = () => {
    setIs_published(true)
    // const updatedData = {
    //   id: editedId,
    //   Question: editedQuestion,
    //   Ans: editedAnswer,
    //   template:editedTemplate,
    //   keyword:editedkeywords,
    // };
    if (!editedkeywords || !editedQuestion || !editedAnswer || !editedTemplate) {
        setErrorMessage('All fields are required');
        return;
    }
    else{
    // setEditedId(""); // Exit edit mode
    seteditformSubmit(true)
    }
  };

  const handleCancelClick = () => {
    onEditmodeChange(false);
    setEditMode(false);
    setEditedId(""); // Exit edit mode without saving
    clearForm();
  };

  
  const OnUploadClick = () => {
    if (!documentName || !selectedFile || !selectedOption ) {
        setUploadErrorMessage('All fields are required');
        return;
    }
    else {
        setUpload(true)
    }
  };

  useEffect(() => {

    if(editformSubmit){
        const formData = new FormData();
        formData.append('question_id', editedId);
        formData.append('keyword', editedkeywords);
        formData.append('question', editedQuestion);
        formData.append('answer', editedAnswer);
        formData.append('templates', editedTemplate);
        formData.append('updated_by', "Admin");
        formData.append('is_published',is_published );
        if(editedTemplate !== "Text"){
            formData.append('template_link', editedTemplateLink);
        }
        else{
            formData.append('template_link', '');
        }
        
        post('edit_question', formData)
    .then((response) => {
    //   seteditformSubmit(false)
    toast.success("Update successful! Your changes have been saved.", {
        autoClose: 3000, 
      });
      setReload(true)
    })
    .catch((error) => {
      toast.error("Internal Server Error (500). Please try again later.", {
        autoClose: 2000, 
      });
    });
    
    }
    setReload(false)
    setEditedId("");
    seteditformSubmit(false)
    setEditMode(false)
    onEditmodeChange(false)


  }, [editformSubmit]);



  if (!data) {
    return ( <div className="no-data-container">
    <p>Please wait Loading ..</p>
  </div> )// Show loading while fetching data
  }

  return (
    <div>
         <Tabs defaultActiveKey="1">
      <TabPane tab={"Published "+'('+  publishedData.length +")"} key="1">

      {publishedData.map((item, index) => (
        <div className="inside-box mt-3" key={item.id}>
          <div className="d-flex align-items-start">
            <div className="number-content">
              <p className="mb-0">{index + 1}</p>
            </div>
            <div className="content-right w-100">
                
              {editedId === item.id ? (
                // Render editable input fields if this is the item being edited, styled like the provided section
                <div className="add-new-ques mb-5">
                     <div>
                                    <h6 className="mb-3">Keywords</h6>
                                    <input
                                        type="text"
                                        className="w-25 form-control mb-2"
                                        placeholder="Enter Keywords here"
                                        value={editedkeywords}
                                        onChange={(e) => seteditedkeywords(e.target.value)}
                                    />
                                </div>
                  <h6 className="mb-3">Question</h6>
                  <input
                    type="text"
                    className="w-100 form-control mb-2"
                    placeholder="Ask your question here"
                    value={editedQuestion}
                    onChange={(e) => setEditedQuestion(e.target.value)}
                  />
                  <h6 className="mb-2">Answer</h6>
                  <textarea
                    className="w-100 p-3"
                    rows="3"
                    value={editedAnswer}
                    onChange={(e) => setEditedAnswer(e.target.value)}
                  />
                 <div className="w-25">
                                        <h6 className="mb-3">Template :</h6>
                                        <Dropdown
                                            options={template_OPtions ||options}
                                            onChange={(option) => seteditedTemplate(option.value)}
                                            value={editedTemplate}
                                            placeholder="Select an option"
                                        />
                                    </div>
                  <div className="w-100 mt-3 ">
                  {editedTemplate !=='Text' && (<>  
                                    <div className="mb-3">
                                        <h6 className="my-2">Link :</h6>
                                        <Dropdown
                                            options={template_link_options}
                                            onChange={(option) => seteditedTemplateLink(option.value)}
                                            value={editedTemplateLink}
                                            placeholder="Select a Link"
                                            className="w-100"
                                        />
                                    
                                    </div>
                                    </>)}
                  {errorMessage && <p className="text-danger">{errorMessage}</p>}
                    <button
                      className="btn btn-primary me-2"
                      onClick={handlePublishClick}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-outline-primary me-2"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                // Render normal view when not editing
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div>
                  {/* <h6>
                  keywords: <span className="ms-3">{item.keywords}</span>
                </h6> */}
                    <h5>{item.Question}</h5>
                    <p>{item.Ans}</p>
                    <div>
                <h6 style={{fontSize:'14px', color: '#a4abc8'}}>
                  Template: <span className="ms-3" style={{fontWeight: '600',
                fontSize: '12px',
                textTransform: 'capitalize',
                color: '#2d40db',
                padding: '4px 15px',
                borderRadius: '15px',
                background: '#cacefe'}}>{item.template}</span>
                            </h6>
                            <div className="d-flex align-items-center mt-3" style={{border: ' 1px solid #4E6A9F4D', borderRadius: '10px', width: 'fit-content',
                padding: '5px 14px'}}>
                            <img src={viewIconCount} alt="" style={{width: '22px'}} />
                            <p className="mb-0 ms-2" style={{color: '#4E6A9F'
            }}> {item.views_count || 0 } Views </p>
                </div>
              </div>
                  </div>
                  
                  <div>
                    <a
                      href="#"
                      onClick={() =>
                        handleEditClick(item.id, item.Question, item.Ans,item.template,item.keywords,item.template_link)
                      }
                    >
                      <img
                        src={Edit}
                        alt="Edit"
                        style={{ width: "15px" }}
                      />
                    </a>
                  </div>
                </div>
              )}
            
            </div>
          </div>
        </div>
      ))}

      </TabPane>
      <TabPane tab={"Drafts ("+  draftData.length +")"}  key="2">
      {draftData.map((item, index) => (
        <div className="inside-box mt-3" key={item.id}>
          <div className="d-flex align-items-start">
            <div className="number-content">
              <p className="mb-0">{index + 1}</p>
            </div>
            <div className="content-right w-100">
                
              {editedId === item.id ? (
                // Render editable input fields if this is the item being edited, styled like the provided section
                <div className="add-new-ques mb-5">
                     <div>
                                    <h6 className="mb-3">Keywords</h6>
                                    <input
                                        type="text"
                                        className="w-25 form-control mb-2"
                                        placeholder="Enter Keywords here"
                                        value={editedkeywords}
                                        onChange={(e) => seteditedkeywords(e.target.value)}
                                    />
                                </div>
                  <h6 className="mb-3">Question</h6>
                  <input
                    type="text"
                    className="w-100 form-control mb-2"
                    placeholder="Ask your question here"
                    value={editedQuestion}
                    onChange={(e) => setEditedQuestion(e.target.value)}
                  />
                  <h6 className="mb-2">Answer</h6>
                  <textarea
                    className="w-100 p-3"
                    rows="3"
                    value={editedAnswer}
                    onChange={(e) => setEditedAnswer(e.target.value)}
                  />
                 <div className="w-25">
                                        <h6 className="mb-3">Template :</h6>
                                        <Dropdown
                                            options={template_OPtions ||options}
                                            onChange={(option) => seteditedTemplate(option.value)}
                                            value={editedTemplate}
                                            placeholder="Select an option"
                                        />
                                    </div>
                                    <div className="w-100 mt-3 ">

                  {editedTemplate !=='Text' && (<>  
                    <div className="mb-3">
                                        <h6 className="my-2">Link :</h6>
                                        <Dropdown
                                            options={template_link_options}
                                            onChange={(option) => seteditedTemplateLink(option.value)}
                                            value={editedTemplateLink}
                                            placeholder="Select a Link"
                                            className="w-100"
                                        />
                                    
                                    </div>
                                    </>)}
                  {errorMessage && <p className="text-danger">{errorMessage}</p>}
                    <button
                      className="btn btn-primary me-2"
                      onClick={handleSaveClick}
                    >
                      Save as Draft
                    </button>
                    <button
                      className="btn btn-primary me-2"
                      onClick={handlePublishClick}
                    >
                      Publish
                    </button>
                    <button
                      className="btn btn-outline-primary me-2"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                // Render normal view when not editing
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div>
                  {/* <h6>
                  keywords: <span className="ms-3">{item.keywords}</span>
                </h6> */}
                    <h5>{item.Question}</h5>
                    <p>{item.Ans}</p>
                    <div>
                    <h6 style={{fontSize:'14px', color: '#a4abc8'}}>
                  Template: <span className="ms-3">{item.template}</span>
                </h6>
              </div>
                  </div>
                  
                  <div>
                    <a
                      href="#"
                      onClick={() =>
                        handleEditClick(item.id, item.Question, item.Ans,item.template,item.keywords,item.template_link)
                      }
                    >
                      <img
                        src={Edit}
                        alt="Edit"
                        style={{ width: "15px" }}
                      />
                    </a>
                  </div>
                </div>
              )}
            
            </div>
          </div>
        </div>
      ))}

      </TabPane>
      <TabPane tab="Upload" key="3">
        {!showUploadform && (<> 
        <div className="w-100 text-start">
                              <button
                                className="btn-outline-primary btn btn-hover-outline"
                                onClick={showUploadformclick}
                                style={{ padding: '8px 23px' }}
                            >
                                 <img
                                    src={UploadIcon}
                                    alt="Plus"
                                    style={{ width: '15px', marginRight: '15px' }}
                                />
                                Upload Document
                            </button>
                            </div>
                            </>)}

                {showUploadform && (<> 
              <div className="row ">

                  <div className="col-lg-6 ">
                 
                      <div className="mb-3">
                        {/* <label for="Name" className="form-label">Document Name</label> */}
                        <h6>
                        Document Name
                        </h6>
                        <input type="text" placeholder="Type document name here" 
                        value={documentName}
                        onChange={onDocumentnameChange}
                        className="form-control" id="Name" aria-describedby="" />
                      </div>
                      <div className=" mb-3">
                      <h6>
                      Select a File
                        </h6>
                      {/* <label className="input-group-text" for="inputGroupFile01">Upload</label> */}
                      <input type="file" 
                        onChange={handleFileChange} // onChange handler
                       className="form-control" id="inputGroupFile01" />
                        {selectedFile && (
                        <div>
                        <p>Selected file: {selectedFile.name}</p>
                        <p>File size: {selectedFile.size} bytes</p>
                        </div>
                    )}
                    </div>
                    <div className="mb-3">
                        <h6>
                        Select a Folder Path
                        </h6>
                        {/*  */}
                        <Dropdown
                                            options={folderPathlist}
                                            onChange={(option) => setSelectedOption(option.value)}
                                            value={selectedOption}
                                            placeholder="Select a Path"
                                            className="w-100"
                                        />
                        {/*  */}
                      </div>
                      {uploaderrorMessage && <p className="text-danger">{uploaderrorMessage}</p>}
                      <div className="d-flex align-items-center">
                      <button type="submit" onClick={OnUploadClick} class="btn btn-primary">Upload</button>
                      <button className="btn btn-outline-primary ms-4" onClick={cancelUploadformclick}> Cancel</button>
                      </div>
                  </div>
              </div>
              </>)}

              <h5 className="mt-4"> Document List </h5>
                    <DataTable
                      columns={columns}
                      data={uploadedDataList}
                      pagination
                      className="table-style"
                    />
      </TabPane>
    
    </Tabs>
      
    </div>
  );
};

export default EditableBox;











