import React, { useState ,useEffect} from 'react';
import Sidebar from '../components/Sidebar';
import  Header from '../components/Header';
import DataTable from 'react-data-table-component';
import SearchImg from '../assets/images/search.svg';
import { Collapse } from 'antd';
import { Input, Modal, Button } from "antd";
import { post,get } from '../lib/HttpServices';
import { Tooltip } from 'react-tooltip';
import { parse, format } from 'date-fns';
import ProductList from '../assets/images/product-list.png';
import ServicesList from '../assets/images/services-list.png';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';


const RegisteredUser = () => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    // const [userQData, setuserQData] = useState([]);
    const[locationfullList,setlocationfullList] =useState([]);
    const [customer_data, setcustomer_data] = useState([]);
    const [customer_filtered_data, setcustomer_filtered_data] = useState([]);
    const [fiterText,setfiterText]= useState('');
    const[selectedLocations,setselectedLocations] = useState([]);

    const [brochuresDataProducts, setBrochuresDataProducts] = useState({});
    const [serviceProducts, setserviceProducts] = useState({});
    const [filteredBrochuresDataProducts, setFilteredBrochuresDataProducts] = useState({});
    const [filteredServiceProducts, setFilteredServiceProducts] = useState({});
    const [filterText, setFilterText] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleView = (row) => {
        setSelectedUser(row);  // Set the selected user data to show in the modal
        setIsModalOpen(true);  // Open the modal
    };

    const handleClose = () => {
        setIsModalOpen(false);  // Close the modal
    };
  
    const toggleSidebar = () => {
      setIsSidebarOpen(prevState => !prevState);
    };
   

      
      

      useEffect(() => {
        get('get_locations')
        .then((res) => {
          setlocationfullList(res.data.data.locations)
        })
        
      }, []);
      
      useEffect(() => {
        // localStorage.setItem('reg_user_count', 23);

    //     get('get_dashboard_users')
    //     .then((res) => {
    //       const temp_data = res.data.data
    //       setcustomer_data(temp_data)
    // //   const sorted_data = sortByDate(temp_data)
    //   setcustomer_filtered_data(sorted_data)
    //     }) .catch((error) => {
    //       console.error("Error during API call:", error);
    //     });
    // ===========
        // console.log(data)
        // setcustomer_data(data)
        // setcustomer_filtered_data(data)
        
    }, []);

    useEffect(() => {
        // Filter customer data based on selected locations
        if(customer_data.length > 0){
        if (selectedLocations.length > 0 )  {
            console.log("stupid----------------")
          const filteredData = customer_data.filter((customer) =>
            selectedLocations.includes(customer.location)
          );
          setcustomer_filtered_data(filteredData);
        } else {
          // If no locations are selected, show all data
          setcustomer_filtered_data(customer_data);
        }
    }
      }, [selectedLocations]);



    const handleLocationChange = (selectedLocations) => {
        const temp_selected_locations = selectedLocations
        setselectedLocations(temp_selected_locations)
      };


      const onChangeFilter = async (e) => {
        const tempFilterText = e.target.value.toLowerCase();
        setFilterText(tempFilterText);
      
        // Filter brochures data
        const filteredBrochures = Object.keys(brochuresDataProducts)
          .filter((key) => key.toLowerCase().includes(tempFilterText) || 
                          JSON.stringify(brochuresDataProducts[key]).toLowerCase().includes(tempFilterText))
          .reduce((acc, key) => ({ ...acc, [key]: brochuresDataProducts[key] }), {});
      
        // Filter services data
        const filteredServices = Object.keys(serviceProducts)
          .filter((key) => key.toLowerCase().includes(tempFilterText) || 
                          JSON.stringify(serviceProducts[key]).toLowerCase().includes(tempFilterText))
          .reduce((acc, key) => ({ ...acc, [key]: serviceProducts[key] }), {});
      
        setFilteredBrochuresDataProducts(filteredBrochures);
        setFilteredServiceProducts(filteredServices);
        
      };

      useEffect(() => {
        const fetchproductservices = async () => {
          setIsLoading(true)
          
          try {
            const response = await get(`get_downloaded_items`);
            const brochures = response?.data?.data?.brochures || {};
            const services = response?.data?.data?.services || {};
      
            if (Object.keys(brochures).length > 0) {
              setBrochuresDataProducts(brochures);
            } else {
              toast.error("No brochures data found");
            }
      
            if (Object.keys(services).length > 0) {
              setserviceProducts(services);
            } else {
              toast.error("No services data found");
            }

            setFilteredBrochuresDataProducts(brochures);
            setFilteredServiceProducts(services);
            
            
           
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setIsLoading(false); 
          }
        };
    
        // Call the async function
        fetchproductservices();
    
        // Cleanup function in case the component unmounts during the request
        return () => {
          setIsLoading(false); // Clean up the loading state if the component unmounts
          
        };
      }, []);

      


  
        const columnsProductBrochure = [
          {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
          },

        {
            name: 'Name',
            selector: row => row.name,
            // selector: (row) => parse(row.date_time, 'dd MMM yyyy, hh:mm a', new Date()), 
            // cell: (row) => <div>{row.date_time}</div>,
            // sortable: true,
        },
        {
          name: 'Phone Number',
          selector: row => row.mobile,
        //   selector: row => row.phone_number,
        //   sortable: true,
        },
    ];
    

    // Filtered items for brochure products
  const itemsProduct = Object.keys(filteredBrochuresDataProducts).map((brochure, index) => {
  // Filter the data based on the search text within each brochure
  const filteredData = filterText
    ? filteredBrochuresDataProducts[brochure].filter((item) => JSON.stringify(item).toLowerCase().includes(filterText))
    : filteredBrochuresDataProducts[brochure];

  return {
    key: index + 1,
    label: (
      <div className="d-flex align-items-center" style={{ fontSize: '15px', fontWeight: '600', color: '#4E6A9F' }}>
        <p style={{ 
          marginBottom: 0, color: '#888', marginRight: '15px', background: 'rgb(164, 171, 200)', 
          padding: '3px 10px', color: 'rgb(255, 255, 255)', borderRadius: '16px', fontSize: '14px',
        }}>
          {filteredData.length}
        </p>
        {brochure}
      </div>
    ),
    children: (
      <DataTable
        columns={columnsProductBrochure}
        data={filteredData}
        pagination
        className="table-style"
      />
    ),
  };
});

// Filtered items for service products
const itemsServices = Object.keys(filteredServiceProducts).map((serviceName, index) => {
  // Filter the data based on the search text within each service
  const filteredData = filterText
    ? filteredServiceProducts[serviceName].filter((item) => JSON.stringify(item).toLowerCase().includes(filterText))
    : filteredServiceProducts[serviceName];

  return {
    key: index + 1,
    label: (
      <div className="d-flex align-items-center" style={{ fontSize: '15px', fontWeight: '600', color: '#4E6A9F' }}>
        <p
          style={{
            marginBottom: 0,
            color: '#FFF',
            marginRight: '15px',
            background: 'rgb(164, 171, 200)',
            padding: '3px 10px',
            borderRadius: '16px',
            fontSize: '14px',
          }}
        >
          {filteredData.length}
        </p>
        {serviceName}
      </div>
    ),
    children: (
      <DataTable
        columns={columnsProductBrochure}
        data={filteredData}
        pagination
        className="table-style"
      />
    ),
  };
});

    
       


    return (
        <div>
            <Sidebar isSidebarOpen={isSidebarOpen} />
                <section className="home-section">
                    <Header toggleSidebar={toggleSidebar} />
                        <div className="project-task">
                            <div class="inside-box p-4">
                            <div className="d-flex align-items-center justify-content-between w-100 mb-4">
                            <div><p style={{color: 'rgb(168, 167, 167)', fontStyle: 'italic'}} className="mb-0">User Downloaded List</p></div>
                                    {/*  */}
                                    <div className="d-flex align-items-center justify-content-between">
                                <form action="">
                                    <div className="bg-light rounded rounded-pill shadow-sm me-3" style={{ border: '1px solid #DEDEDE', minWidth: '370px' 
                                    }}>
                                        <div className="input-group search-space">
                                        <Input type="search" placeholder="Search with Name or Date" value={filterText} onChange={onChangeFilter}  aria-describedby="button-addon1" className=" ms-2 form-control rounded-pill border-0 bg-light"  />
                                        <div className="input-group-append">
                                            <button id="button-addon1" type="submit" className="btn btn-link text-primary border-0">
                                            <img src={SearchImg} alt="" className="" /> 
                                                </button>
                                        </div>
                                        </div>
                                       
                                    </div>
                                    </form>

                                    </div>
                                    </div>
                                    {/* accordion */}

                                  
                                    {/* Conditionally render content or loading component */}
                                    {isLoading ? (
                                      <Loading /> // Use the Loading component
                                    ) : (
                                      <>
                                    <div className="product-list">
                                        <div className="d-flex align-items-center mb-3">
                                        <img src={ProductList} alt="product List" style={{width: '23px'}} />
                                            <h5 class="ms-2 mb-0 ">
                                            Products
                                            </h5>
                                        </div>
                                        <Collapse accordion items={itemsProduct} />
                                    </div>
                                    <div className="services-list">
                                        <div className="d-flex align-items-center mb-3 mt-3">
                                        <img src={ServicesList} alt="services List" style={{width: '23px'}} />
                                            <h5 class="ms-2 mb-0">
                                            Services
                                            </h5>
                                        </div>
                                        <Collapse accordion items={itemsServices} />
                                    </div>
                                    </>
            )}
                                    {/* end accordion */}
                            </div>
                        </div>
                     
            </section>
        </div>
    )
}

export default RegisteredUser;
