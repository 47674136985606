// Loading.js
import React from 'react';

const Loading = () => {
  return (
    <div style={{ textAlign: 'center', color: '#4E6A9F', fontSize: '18px', marginTop: '20px' }}>
      <span>Loading...</span> {/* You can replace this with a spinner if desired */}
    </div>
  );
};

export default Loading;
