import axios from 'axios';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const history = createBrowserHistory();
// const navigate = useNavigate();


 let apiRoot =process.env.REACT_APP_API_KEY;
  // let apiRoot = 'http://192.168.96.111:5000/api/'
// let apiRoot = 'http://192.168.96.10:5000/';
// let apiRoot ='https://chatbot.dcubeai.com/';



 const getAccessToken = () => localStorage.getItem('ChatData');
const setAccessToken = (token) => localStorage.setItem('ChatData', token);

 const getRefreshToken = () => localStorage.getItem('refresh_token');
const setRefreshToken = (token) => localStorage.setItem('refresh_token', token);

// Optional: Clear tokens (e.g., on logout)
const clearTokens = () => {
  localStorage.removeItem('ChatData');
  localStorage.removeItem('refresh_token');
};


const config = {
  headers: {
    'Content-Type': 'application/json',
    // "Authorization":`Bearer ${data?.token}`,
   }
};

const axiosInstance = axios.create({
  baseURL: apiRoot,
});



// Function to refresh the token
const refreshToken = async () => {
  const refresh_token = getRefreshToken();

  if (!refresh_token) {
    throw new Error('No refresh token available.');
  }

  try {
    const response = await axios.post(
      `${apiRoot}refresh`, 
      {}, // No body needed since the refresh token is in the header
      { 
        headers: { 
          'Authorization': `Bearer ${refresh_token}` 
        } 
      }
    );

    const { access_token } = response.data; // Extract access_token

    setAccessToken(access_token); // Update access token in localStorage

    return access_token;
  } catch (error) {
    // useNavigate('');
    // navigate('/')
    window.location.replace('/');
    throw error;
  }
};


// Request Interceptor: Add the access token to every request
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Response Interceptor: Refresh token logic on 401 errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshToken();
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        toast.error('Session expired, please log in again.', {
          autoClose: 2000,
        });
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);



function errorHandler(error) {
  if (error) {
    let { response } = error;
    let { data } = response || {};
    if (error && error.isAxiosError && !response) {
      toast.error('Network Issue or Server Down', {
        autoClose: 3000,
        toastId: 'network',
      });
    } 
    // else if (response && response.status == 401) {
    //   toast.error(data.message.message, {
    //     autoClose: 3000,
    //     toastId: 'network',
    //   });
    // } 
    else if (response && response.status == 401) {
      toast.error("Token has expired please login", {
        autoClose: 3000,
        toastId: 'network',
      });
    } 
    else if (data && data.statusCode && data.statusCode == 400 && data.message && data.message.message) {
      toast.error(response.data.message.message.join('\n'), {
        autoClose: 3000,
        toastId: 'network',
      });
    } else if (data && data.statusCode && data.statusCode == 400 && data.message) {
      toast.error(response.data.message.message, {
        autoClose: 3000,
        toastId: 'network',
      });
    } else {
      // toast.error(JSON.stringify(data), {
      //   autoClose: 3000,
      //   toastId: 'network',
      // });
    }
  }
}



class httpService {
  // constructor() {
  //   if (axiosInstance.interceptors.request.handlers.length === 0) {
  //     axiosInstance.interceptors.request.use((request) =>
  //       requestHandler(request)
  //     );

  //     axiosInstance.interceptors.response.use(
  //       (response) => responseSuccess(response),
  //       (error) => responseFailed(error)
  //     );
  //   }
  // }

  getWithHeader(path, config = {}) {
    if (path) {
      return axiosInstance.get(apiRoot + path, config);
    }
  }


  async get(path, model = {}) {
    
    
    return new Promise((resolve, reject) => {
      if (path) {

        try {
          // Merging `model` into `config` under `params`
          const axiosConfig = {
            ...config,
            params: model, // Assign query params here
          };
  
          return axiosInstance.get(apiRoot + path, axiosConfig)
            .then((result) => {
              // console.log("Result:", result);
              resolve(result);
            })
            .catch((error) => {
              errorHandler(error);
              reject(error);
            });
        } catch (error) {
          if (error.response) {
            if (error.response.status === 500) {
              toast.error("Internal Server Error (500). Please try again later.", {
                autoClose: 2000, 
              });
            } else {
              toast.error(`Error: ${error.response.status}. Please try again.`, {
                autoClose: 2000, 
              });
            }
          } else if (error.request) {
            // Handle case when the request was made but no response was received
            toast.error("No response from server. Please check your connection.", {
              autoClose: 2000, 
            });
          } else {
            // Handle other errors like request setup issues
            toast.error("Error setting up the API call. Please try again.", {
              autoClose: 2000, 
            });
          }
          reject(error);
        }
      }
    });
  }
  
  


  // async get(path, model = {}) {
  //   console.log("path is path ", apiRoot + path);
  //   return new Promise((resolve, reject) => {
  //     if (path) {
  //       try {
  //         // Merge config and add 'params' for query parameters
  //         const axiosConfig = {
  //           ...config,
  //           params: model, // Pass query parameters here
  //         };
  
  //         return axiosInstance
  //           .get(apiRoot + path, axiosConfig)
  //           .then((result) => {
  //             resolve(result);
  //           })
  //           .catch((error) => {
  //             errorHandler(error);
  //             reject(error);
  //           });
  //       } catch (error) {
  //         reject(error);
  //       }
  //     }
  //   });
  // }

  // post(path, model) {
  //   return new Promise((resolve, reject) => {
  //     if (path) {
  //       try {
  //         return axiosInstance
  //           .post(apiRoot + path, model, config)
  //           .then((result) => {
  //             resolve(result);
  //           })
  //           .catch((error) => {
  //             errorHandler(error);
  //             reject(error);
  //           });
  //       } catch (error) {
  //         reject(error);
  //       }
  //     }
  //   });
  // }



  post(path, model) {
    return new Promise((resolve, reject) => {
      if (path) {
        try {
          // Check if model is FormData 'Content-Type': 'multipart/form-data'
          const isFormData = model instanceof FormData;
  
          // Set headers based on data type
          const config = {
            headers: {
              // 'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
              'Content-Type': isFormData ? undefined : 'application/json', 
              // Add Authorization or other headers if needed
              // 'Authorization': `Bearer ${data?.token}`
            },
          };
  
          // Make the post request
          return axiosInstance
            .post(apiRoot + path, model, config)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              errorHandler(error);
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      }
    });
  }

  put(path, model) {
    return new Promise((resolve, reject) => {
      if (path) {
        try {
          return axiosInstance.put(apiRoot + path, model, config)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              errorHandler(error);
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      }
    });
  }
  patch(path, model) {
    return new Promise((resolve, reject) => {
      if (path) {
        try {
          return axiosInstance.patch(apiRoot + path, model, config)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              errorHandler(error);
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      }
    });
  }
  remove(path, model) {
    return new Promise((resolve, reject) => {
      if (path) {
        try {
          return axiosInstance.delete(apiRoot + path, { data: model }, config)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              errorHandler(error);
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      }
    });
  }
}

export const http = new httpService();
export const getWithHeader = new httpService().getWithHeader;
export const get = new httpService().get;
export const post = new httpService().post;
export const put = new httpService().put;
export const patch = new httpService().patch;
export const remove = new httpService().remove;
export const apiUrl = apiRoot;