import React from 'react';


const BarDiagram = ({ data }) => {
  return (
    <div className="position-relative d-flex align-items-baseline bar-subtitle justify-content-between">
      {data.map((item, index) => (
        <div key={index} className="text-center">
          <div className="barcontainer">
            <div
              className="bar"
              style={{ height: `${item.percentage}%` }} // Only height is set dynamically
            ></div>
          </div>
          <p style={{ marginBottom: '0', textAlign: 'center' }}>{item.country}</p>
        </div>
      ))}
    </div>
  );
};

export default BarDiagram;
