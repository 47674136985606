import React, { useState, useRef, useEffect } from 'react';
import sortImg from '../assets/images/sort.svg'; // Your image path here

const LocationCheckboxDropdown = ({ locations, onLocationChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const dropdownRef = useRef(null);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle checkbox change
  const handleLocationChange = (location) => {
    const updatedLocations = selectedLocations.includes(location)
      ? selectedLocations.filter((item) => item !== location)
      : [...selectedLocations, location];
    setSelectedLocations(updatedLocations);
    onLocationChange(updatedLocations); // Pass selected locations back to parent
  };

   // Close dropdown when clicking outside
   const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} style={{ position: 'relative', display: 'inline-block' }} className="me-3">
      <button
        onClick={toggleDropdown}
        className="custom-dropdown-toggle"
        style={{
          padding: '9px 35px 9px 15px',
          border: '1px solid #DEDEDE',
          borderRadius: '20px',
          display: 'flex',
          alignItems: 'center',
          background: 'none',
          cursor: 'pointer',
        }}
      >
        <img
          src={sortImg}
          alt="sort"
          style={{ width: '20px', marginRight: '10px' }}
        />
        Filter by Location
       
      </button>

      {isOpen && (
        <ul
          className="dropdown-menu"
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            right: '0',
            padding: '10px',
            border: '1px solid #DEDEDE',
            borderRadius: '10px',
            backgroundColor: '#fff',
            zIndex: 10,
            maxHeight: '200px',
            overflowY: 'auto',
            marginTop: '5px',
            display: 'block',
          }}
        >
          {locations.map((location, index) => (
            <li key={index} style={{ listStyle: 'none', marginBottom: '10px' }}>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  value={location}
                  checked={selectedLocations.includes(location)}
                  onChange={() => handleLocationChange(location)}
                  style={{ marginRight: '10px' }}
                />
                {location}
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationCheckboxDropdown;
