import React, { useState ,useEffect} from 'react';
import Sidebar from '../components/Sidebar';
import { DatePicker } from 'antd';
import  Header from '../components/Header';
import { Input } from "antd";
import LeadsImg from '../assets/images/leads.svg';
import CarbonLocation from '../assets/images/carbon_location.svg';
import sortImg from '../assets/images/sort.svg';
import dateImg from '../assets/images/date.svg';
import SearchImg from '../assets/images/search.svg';
import { Button, Dropdown, Space ,Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import BarDiagram from '../components/BarDiagram';
import DynamicTable from '../components/DynamicTable';
import DataTable from "react-data-table-component";
import LocationCheckboxDropdown from '../components/filterByLocation';
import FilterByDate from '../components/filterByDate';
import { useNavigate } from 'react-router-dom';
import { get } from '../lib/HttpServices'; 
import axios from 'axios';
import { toast } from 'react-toastify';
import { parse, format } from 'date-fns';
// import { Line } from '@ant-design/charts';
import {
  Line,
  LineChart,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
} from "recharts";



const lineData = [
  { date: 'Sep 10 2024', leads: 12 },
  { date: 'Sep 11 2024', leads: 15 },
  { date: 'Sep 12 2024', leads: 9 },
  { date: 'Sep 13 2024', leads: 7 },
  { date: 'Sep 14 2024', leads: 18 },
  { date: 'Sep 15 2024', leads: 22 },
  { date: 'Sep 16 2024', leads: 19 },
];
const items = [
  {
    key: '4',
    label: 'Last 7 Days',
    value: 'Last 7 Days',
  },
  {
    key: '1',
    label: 'Last 14 Days',
    value: 'Last 14 Days',
  },
  {
    key: '2',
    label: 'Last 30 Days',
    value: 'Last 30 Days',
  },
  {
    key: '3',
    label: 'Last 60 Days',
    value: 'Last 60 Days',
  },
];


const Dashboard = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selected, setSelected] = useState('Last 7 Days'); // Initial selected value
    const [selectedLocmenu, setselectedLocmenu] = useState('Last 7 Days'); // Initial selected value
    const[totalLeadPercentage,setotalLeadPercentage] = useState('51');
    const[totalLeads,setotalLead] = useState('--');
    const[locationfullList,setlocationfullList] =useState([]);
    const[leadgraphData,setleadgraphData] =useState([]);
    const[totalLocations,setotalocations] = useState('--');
    const [LocationBarData,setlocationbardata] = useState([]);
    const[leadCustomerData,setleadCustomerData] = useState([]);
    const[filterCustomerData,setfilterCustomerData] = useState([]);
    const [fiterText,setfiterText]= useState('');
    const[selectedLocations,setselectedLocations] = useState([]);
    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
    const navigate = useNavigate();
    const lead_opt_dict  = {"Last 7 Days":"7","Last 4 Days":"4","Last 2 Days":"2","Last 1 Day":"1","Last 14 Days":"14","Last 30 Days":"30" ,"Last 60 Days":"60"}
    const toggleDropdown = () => { setIsDropdownOpen(!isDropdownOpen); };
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const leadData = {"total_leads" : 136,"percentage":51}
    const totalLocationData = {"total_locations":25,"bardiagram_data":[
      { country: 'AUS', percentage: 10 },
      { country: 'BEL', percentage: 100 },
      { country: 'CAN', percentage: 90 },
      { country: 'DNK', percentage: 90 },
      { country: 'FIN', percentage: 80 },
      { country: 'FRA', percentage: 70 },
      { country: 'GRC', percentage: 30 },
      { country: 'HUN', percentage: 70 },
      { country: 'IND', percentage: 80 },
      { country: 'ITA', percentage: 20 },
      { country: 'JP', percentage: 60 },
      { country: 'KOR', percentage: 50 },
      { country: 'USA', percentage: 100 }
    ]}
    const locations = ['AUS', 'BEL','IND','FRA', 'CAN', 'HUN', 'FIN','KOR','USA'];
    const myNewTheme = {
      rows: {
        fontSize: "50px",
      },
    };

    const handleDateRangeChange = (startDate, endDate) => {
      if (startDate && endDate) {
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    
        // const startDateString = startDate.toString();
        // const endDateString = endDate.toString();
        const startDateString_formatted = `${startDate.toLocaleDateString('en-US', options).replace(/,/g, '')} 00:00:00`;
        const endDateString_formatted = `${endDate.toLocaleDateString('en-US', options).replace(/,/g, '')} 00:00:00`;
        setDateRange({ startDateString_formatted, endDateString_formatted });
      } else {
        // Handle case when either or both dates are null (reset)
        setDateRange({ startDateString_formatted: null, endDateString_formatted: null });
      }
    };

    const handleViewClick = (lead_id, name,date,location) => {
      // Navigate to the ChatView component
      navigate(`/chatbot/${lead_id}`, {
        state: { lead_id, name ,date,location}, // Pass customer_id and name as state
      });
    };

    // const handleViewClick = (lead_id, name, date, location) => {
    //   // Construct the URL for the new tab
    //    // If needed, you can also store the additional state like lead_id, date, and location in sessionStorage or localStorage
    //    sessionStorage.setItem('chatViewData', JSON.stringify({ lead_id, name, date, location }));
    //   const url = `/chatbot/${name}`;
    //   // Open the new tab
    //   window.open(url, '_blank');
    
     
    // };
    const columns = [
  
      {
        name: 'Date',
        selector: (row) => parse(row.date, 'dd MMM yyyy, hh:mm a', new Date()),  // Parse based on the date format
        // cell: (row) => <div>{format(parse(row.date, 'dd MMM yyyy, hh:mm a', new Date()), 'PPpp')}</div>, // Reformat if needed
        cell: (row) => <div>{row.date}</div>,
        sortable: true,
      },
  
      {
        name: <div>Mobile</div>,
        selector: (row, i) => row.mobile,
        // cell: (row) => <div>{row.lastName}</div>,
        sortable: true,
      },
      {
        name: <div>Location</div>,
        selector: (row, i) => row.location,
        sortable: true,
      },
      {
        name: <div>Name</div>,
        //  selector: "lastName",
        selector: (row, i) => row.name,
        // cell: (row) => <div>{row.lastName}</div>,
        sortable: true,
      },
      {
        name: "Actions",
        // width: "4rem",
        selector: (row) => (
          <div>
            <button
          className="btn custom-btn-sec"
          onClick={() => handleViewClick(row.mobile, row.name,row.date, row.location)}
        >
          View
        </button>
          </div>
        ),
      },]

      const handleLocationChange = (selectedLocations) => {
        const temp_selected_locations = selectedLocations
        setselectedLocations(temp_selected_locations)
      };



      useEffect(() => {
      //   fetch('http://192.168.96.111:5000/apis/list_tenant_customers', {
      //     method: 'GET',
      //     headers: { 'Content-Type': 'application/json' }
      // })
      // .then(response => response.json())
      // .then(data => console.log("Fetched Data:", data))
      // .catch(error => console.error("Fetch Error:", error));



      //   get('get_dashboard_locations',{
      //     "days":"7","name":"sac"
      // })
      //   .then((res) => {
      
      //   })
       
        

        get(`get_dashboard_leads?days=${lead_opt_dict[selected]}`)
          .then((res) => {
            setotalLead(res.data.data.total_leads);
            setleadgraphData(res.data.data.leads);
            setotalLeadPercentage(leadData.percentage)
          })
          .catch((error) => {
            if (error.response) {
             
            console.log("errr");
            }
          });
   
      }, [selected]);

      // useEffect(() => {
      //   const axiosInstance = axios.create({
      //     baseURL: 'http://192.168.96.111:5000',
      //     timeout: 10000, // Set a global timeout for all requests
      //   });
      //   axios.get('http://192.168.96.111:5000/apis/list_tenant_customers', {
      //     timeout: 10000 // 10 seconds
      //   })
      //   .then(response => {
      //     console.log("Axios fetch success data", response.data);
      //   })
      //   .catch(error => {
      //     console.log("Error occurred:", error.message); // Log a more specific error message
      //   });
      // }, []);
      

      useEffect(() => {
        // Filter customer data based on selected locations
        if (selectedLocations.length > 0) {
          const filteredData = leadCustomerData.filter((customer) =>
            selectedLocations.includes(customer.location)
          );
          setfilterCustomerData(filteredData);
        } else {
          // If no locations are selected, show all data
          setfilterCustomerData(leadCustomerData);
        }
      }, [selectedLocations]);


      useEffect(() => {
        // Filter customer data based on selected locations
        //
        get(`get_dashboard_locations?days=${lead_opt_dict[selectedLocmenu]}`)
        .then((res) => {
          // console.log("Data:", res.data.data); // Log the data field explicitly
          setotalocations(res.data.data.total_locations);
          setlocationbardata(res.data.data.bardiagram_data)
          
        })
        .catch((error) => {
          if (error.response) {
          console.log("errr in dashbord_leads");
          }
        });
       
      }, [selectedLocmenu]);

    const onChangeFilter = async (e) => {
      // debugger;
      const temp_filter_text = e.target.value
      setfiterText(temp_filter_text)
    
      // Clear the date filter field when typing in the search input
     
      var searchData = leadCustomerData.filter((item) => {
        if (
          // item?.firstName
          //   .toString()
          //   .toLowerCase()
          //   .includes(e.target.value.toLowerCase())
          JSON.stringify(item)
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) !== -1
        ) {
          return item;
        }
      });
    
      setfilterCustomerData(searchData);
    };

    const sortByDate = (data) => {
      return data.sort((a, b) => {
        // Function to convert "20 Jan 2004, 8:00PM" into a Date object
        const parseDate = (dateString) => {
          const [datePart, timePart] = dateString.split(", ");
          const [day, month, year] = datePart.split(" ");
          const time = timePart.replace("PM", " PM").replace("AM", " AM"); // Ensure correct time format
    
          // Months map for converting "Jan", "Feb", etc., into numbers
          const months = {
            Jan: 0,
            Feb: 1,
            Mar: 2,
            Apr: 3,
            May: 4,
            Jun: 5,
            Jul: 6,
            Aug: 7,
            Sep: 8,
            Oct: 9,
            Nov: 10,
            Dec: 11
          };
    
          return new Date(`${year}-${months[month] + 1}-${day} ${time}`);
        };
    
        // Convert both dates and compare them
        const dateA = parseDate(a.date);
        const dateB = parseDate(b.date);
    
        return dateB - dateA; // Sort in descending order (latest date first)
      });
    };
    
    
    

    const CustomerData = [
      {
        lead_id: 1,
        date: "20 Jan 2004, 8:00PM",
        mobile: "8889999445",
        location: "UAE",
        name: "Mathew Philip"
      },
      {
        "lead_id": 4,
        "date": "23 Jan 2004, 6:00PM",
        "mobile": "5556667774",
        "location": "AUS",
        "name": "David Johnson"
      },
      {
        "lead_id": 5,
        "date": "24 Jan 2004, 5:30PM",
        "mobile": "4445556662",
        "location": "CAN",
        "name": "Emma Watson"
      },
      {
        "lead_id": 6,
        "date": "25 Jan 2004, 4:00PM",
        "mobile": "3334445556",
        "location": "FRA",
        "name": "Lucas Miller"
      },
      {
        "lead_id": 7,
        "date": "26 Jan 2004, 3:45PM",
        "mobile": "2223334448",
        "location": "IND",
        "name": "Priya Patel"
      },
      {
        lead_id: 2,
        date: "21 Jan 2004, 9:00PM",
        mobile: "7778889991",
        location: "USA",
        name: "John Doe"
      },
      {
        lead_id: 3,
        date: "22 Jan 2004, 7:30PM",
        mobile: "6667778883",
        location: "UK",
        name: "Jane Smith"
      },
      {
        lead_id: 1,
        date: "20 Jan 2004, 8:00PM",
        mobile: "8889999445",
        location: "UAE",
        name: "Mathew Philip"
      },
      {
        "lead_id": 4,
        "date": "23 Jan 2004, 6:00PM",
        "mobile": "5556667774",
        "location": "AUS",
        "name": "David Johnson"
      },
      {
        "lead_id": 5,
        "date": "24 Jan 2004, 5:30PM",
        "mobile": "4445556662",
        "location": "CAN",
        "name": "Emma Watson"
      },
      {
        "lead_id": 6,
        "date": "25 Jan 2004, 4:00PM",
        "mobile": "3334445556",
        "location": "FRA",
        "name": "Lucas Miller"
      },
      {
        "lead_id": 7,
        "date": "26 Jan 2004, 3:45PM",
        "mobile": "2223334448",
        "location": "IND",
        "name": "Priya Patel"
      },
      {
        lead_id: 2,
        date: "21 Jan 2004, 9:00PM",
        mobile: "7778889991",
        location: "USA",
        name: "John Doe"
      },
      {
        lead_id: 3,
        date: "22 Jan 2004, 7:30PM",
        mobile: "6667778883",
        location: "UK",
        name: "Jane Smith"
      }
      
    ];


    useEffect(() => {
      const { startDateString_formatted, endDateString_formatted } = dateRange;
      if (startDateString_formatted && endDateString_formatted) {
        // console.log("yeah it has date range",dateRange)
        
        if(startDateString_formatted&&endDateString_formatted){
        get(`get_dashboard_users?start_date=${startDateString_formatted}&end_date=${endDateString_formatted}`)
      .then((res) => {
       
        const temp_user_data = res.data.data
        setleadCustomerData(temp_user_data)
    const sorted_data = sortByDate(temp_user_data)
    setfilterCustomerData(sorted_data)
      })
        }
      }

      else {
      get('get_dashboard_users')
      .then((res) => {
        // console.log("Full response object:", res); // Log full response
        // console.log("Data:", res.data); // Log the data field explicitly
        const temp_user_data = res.data.data
        setleadCustomerData(temp_user_data)
    const sorted_data = sortByDate(temp_user_data)
    // console.log("sorted data",sorted_data)
    setfilterCustomerData(sorted_data)
      }) .catch((error) => {
        // Log the error for debugging
        console.error("Error during API call:", error);
        
        // Handle specific HTTP status codes like 500
        // if (error.response) {
        //   if (error.response.status === 500) {
        //     console.error("Server error (500): Something went wrong on the server.");
        //     toast.error("Internal Server Error (500). Please try again later.", {
        //       autoClose: 2000, 
        //     });
        //   } else {
        //     console.error(`API call failed with status ${error.response.status}:`, error.response.data);
        //     toast.error(`Error: ${error.response.status}. Please try again.`, {
        //       autoClose: 2000, 
        //     });
        //   }
        // } else if (error.request) {
        //   // Handle case when the request was made but no response was received
        //   console.error("No response received:", error.request);
        //   toast.error("No response from server. Please check your connection.", {
        //     autoClose: 2000, 
        //   });
        // } else {
        //   // Handle other errors like request setup issues
        //   console.error("Error setting up request:", error.message);
        //   toast.error("Error setting up the API call. Please try again.", {
        //     autoClose: 2000, 
        //   });
        // }
      });
    }
     
    

 
    }, [dateRange]);

    useEffect(() => {
      get('get_locations')
      .then((res) => {
        setlocationfullList(res.data.data.locations)
      })
      
    }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  const handleLeadMenuClick = (e) => {
    const selectedItem = items.find(item => item.key === e.key);
    if (selectedItem) {
      setSelected(selectedItem.value); // Update selected value
      // console.log(selectedItem)
    }
  };

  const handleLocationMenuClick = (e) => {
    const selectedItem = items.find(item => item.key === e.key);
    if (selectedItem) {
      setselectedLocmenu(selectedItem.value); // Update selected value
      
    }
  };
  const leadMenu = (
    <Menu onClick={handleLeadMenuClick}>
      {items.map(item => (
        <Menu.Item key={item.key}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const locationMenu = (
    <Menu onClick={handleLocationMenuClick}>
      {items.map(item => (
        <Menu.Item key={item.key}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

    return (
        <div>
            
            <Sidebar isSidebarOpen={isSidebarOpen} />
            <section className="home-section">
            <Header toggleSidebar={toggleSidebar} />
     
      <div className="project-task">
       {/* <!-- <div className="graph-box-line"> --> */}
        
          <div className="row">
            <div className="col-lg-6 network-width">
              <div className="inside-box">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-start">
                    <div>
                      <img src={LeadsImg} alt="" style={{ width: '35px' }} />
                    </div>
                    <h4 className="mb-0 ms-3">
                      Leads
                    </h4>
                  </div>

                  <div>
                     <Dropdown overlay={leadMenu} trigger={['click']} arrow>
      <a onClick={(e) => e.preventDefault()} style={{ cursor: 'pointer', color: '#5562DA' }}>
        <Space>
          {selected} {/* Display selected value */}
          <DownOutlined style={{ color: '#5562DA' }} />
        </Space>
      </a>
    </Dropdown>
                    {/* <div className="dropdown">
                      <a href="#" className=" custom-dropdown-toggle" onClick={toggleDropdown}>
                        Last 7 Days
                      </a>
                      {isDropdownOpen && (
                        <ul className="dropdown-menu dropdown-menu-end" style={{ display: isDropdownOpen ? 'block' : 'none' }}>
                            <li><a className="dropdown-item" href="#">Last 2 Days</a></li>
                            <li><a className="dropdown-item" href="#">Last 1 Days</a></li>
                            <li><a className="dropdown-item" href="#">Last 4 Days</a></li>
                        </ul>
                        )}
                    </div> */}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-5"> 
                  <div className="d-flex align-items-center justify-content-start">
                    <div className="invisible">
                      <img src={LeadsImg} alt="" style={{ width: '35px' }} />
                    </div>
                    <div className="ms-3">
                      <h1>
                        {totalLeads}
                      </h1>
                      <p className="mb-0" style={{color: '#A4ABC8'}}>
                        Total Leads
                      </p>
                    </div>
                  </div>
                 {/* <div className="svg-style">

                  <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" style={{ overflow: 'initial' }}>
                  <defs>
                      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                      <stop offset="20%" style={{ stopColor: '#5562DA', stopOpacity: '1' }} />
                      <stop offset="60%" style={{ stopColor: '#5562DA', stopOpacity: '1' }} />
                      <stop offset="100%" style={{ stopColor: '#4E6A9F', stopOpacity: '1' }} />

                      </linearGradient>
                  </defs>
                  <circle className="bg" cx="77" cy="77" r="72" />
                  <circle className="meter-1" cx="77" cy="77" r="72" stroke="url(#gradient)" strokeWidth="10" fill="none" />    
                  </svg>
                  <p className="per-graph">{totalLeadPercentage}%</p>


                  </div> */}
                  
              <ResponsiveContainer width="60%" height={200}>
                <LineChart data={leadgraphData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis allowDecimals={false}  />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="count" stroke="#524bd6" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
                </div>
              </div>
              {/* <!--  --> */}

              
            </div>
            <div className="col-lg-6">
              <div className="inside-box">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  <div>
                    <img src={CarbonLocation} alt="" style={{ width: '35px' }}/>
                  </div>
                  <h4 className="mb-0 ms-3">
                    Locations
                  </h4>
                </div>
                <div>
                <Dropdown overlay={locationMenu} trigger={['click']} arrow>
      <a onClick={(e) => e.preventDefault()} style={{ cursor: 'pointer', color: '#5562DA' }}>
        <Space>
          {selectedLocmenu} {/* Display selected value */}
          <DownOutlined style={{ color: '#5562DA' }} />
        </Space>
      </a>
    </Dropdown>
                  {/* <div className="dropdown">
                    <a href="#" className=" custom-dropdown-toggle" data-bs-toggle="dropdown">
                      Last 7 Days
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li><a className="dropdown-item" href="#"> Last 2 Days</a></li>
                      <li><a className="dropdown-item" href="#"> Last 1 Days</a></li>
                      <li><a className="dropdown-item" href="#"> Last 4 Days</a></li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-5"> 
                <div className="d-flex align-items-center justify-content-start">
                  <div className="invisible">
                    <img src={LeadsImg} alt="" style={{ width: '35px' }} />
                  </div>
                  <div className="ms-3">
                    <h1>
                      {totalLocations}
                    </h1>
                    <p className="mb-0" style={{color: '#A4ABC8'}}>
                      Total Locations
                    </p>
                  </div>
                </div>
                {/* <!--  --> */}
                  {/* bardiagram start  */}
                  <BarDiagram data={LocationBarData} />

              {/* bardiagram  end */}
            
          </div>
            
            
          </div>
          {/* <!--  --> */}
        </div>
       {/* <!-- </div> --> */}
       


       
      </div>
      <div className="row my-4">
           <div className="col-lg-12">
              <div className="d-flex align-items-center justify-content-end">
                  <div>
                    <form action="">
                      <div className="bg-light rounded rounded-pill shadow-sm me-3" style={{ border: '1px solid #DEDEDE', minWidth: '370px' 
                      }}>
                        <div className="input-group search-space">
                          <Input type="search" placeholder="Search with Name,Location or Date" value={fiterText} onChange={onChangeFilter} aria-describedby="button-addon1" className=" ms-2 form-control rounded-pill border-0 bg-light"  />
                          <div className="input-group-append">
                            <button id="button-addon1" type="submit" className="btn btn-link text-primary border-0">
                            <img src={SearchImg} alt="" className="" /> 
                                </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  
                  {/* <div>
                    <div className="dropdown sort-btn" style={{ marginLeft: '15px'}}
                     >
                      <a href="#" className=" custom-dropdown-toggle" data-bs-toggle="dropdown" style={{ padding: '9px 35px 9px 15px', border: '1px solid #DEDEDE', borderRadius: '20px' }}>
                       <img src={sortImg} alt="" style={{ width: '20px' }} className="me-2" /> Filter by location
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li><a className="dropdown-item" href="#"> Last 2 Days</a></li>
                        <li><a className="dropdown-item" href="#"> Last 1 Days</a></li>
                        <li><a className="dropdown-item" href="#"> Last 4 Days</a></li>
                      </ul>
                    </div>
                  </div> */}

                 
                  <div>
                  <LocationCheckboxDropdown locations={locationfullList} onLocationChange={handleLocationChange} />
                </div>

                  {/* <div>
                    <div className="dropdown sort-btn" style={{  marginLeft: '15px' }}
                   >
                      <a href="#" className=" custom-dropdown-toggle" data-bs-toggle="dropdown" style={{ padding: '9px 35px 9px 15px', border: '1px solid #DEDEDE',
                      borderRadius: '20px' }}>
                       <img src={dateImg} alt="" style={{ width: '20px' }} className="me-2" /> Any Date
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li><a className="dropdown-item" href="#"> Last 2 Days</a></li>
                        <li><a className="dropdown-item" href="#"> Last 1 Days</a></li>
                        <li><a className="dropdown-item" href="#"> Last 4 Days</a></li>
                      </ul>
                    </div>
                  </div> */}
                   <div>
      <FilterByDate onDateRangeChange={handleDateRangeChange} />
    
    </div>

              </div>
           </div>
           
      </div>
     {/* <!--  --> */}
     <div className="row">
      <div className="col-lg-12">
      {/* <DynamicTable data={filterCustomerData} /> */}
      <DataTable
                  columns={columns}
                  data={filterCustomerData}
                  highlightOnHover
                  customTheme={myNewTheme}
                  pagination
                  // selectableRows={true}
                  // contextActions={contextActions}
                 
                  // fixedHeader
                  // selectableRowsVisibleOnly={false}
                  options={{
                    disableSelectionOnClick: true,
                    noHeader: true
                  }}
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[10, 20, 50, 100]}
                  paginationComponentOptions={{
                    rowsPerPageText: "Records per page:",
                    rangeSeparatorText: "out of",
                  }}
                  className="table-style "
                />
      </div>
     </div>


     </div>
     {/* <!--  --> */}
    </section>
        </div>
    );
}

export default Dashboard;
