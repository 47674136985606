import React, { useState ,useEffect} from 'react';
import Sidebar from '../components/Sidebar';
import  Header from '../components/Header';
import DataTable from 'react-data-table-component';
import { post,get } from '../lib/HttpServices';
import { Tooltip } from 'react-tooltip';
import { parse, format } from 'date-fns';


const UserQueries = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [userQData, setuserQData] = useState([]);
    const toggleSidebar = () => {
      setIsSidebarOpen(prevState => !prevState);
    };
    const data = [
        { id: 1, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
        { id: 2, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
        { id: 3, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
        { id: 4, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
        { id: 5, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
        { id: 6, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
        { id: 7, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
        { id: 8, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
        { id: 9, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
        { id: 10, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
        { id: 11, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
        { id: 12, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
        { id: 13, date: '22/10/2023', mobile: '9547456544', name: 'Sahi', usermessage: '', adminreply: '', status: ''   },
      ];
    const columns = [
        // {
        //   name: 'Date',
        //   selector: row => row.date_time,
        //   sortable: true,
        // },
        {
            name: 'Date',
            selector: (row) => parse(row.date_time, 'dd MMM yyyy, hh:mm a', new Date()),  // Parse based on the date format
            // cell: (row) => <div>{format(parse(row.date_time, 'dd MMM yyyy, hh:mm a', new Date()), 'PPpp')}</div>, // Reformat if needed
            cell: (row) => <div>{row.date_time}</div>,
            sortable: true,
          },
        {
          name: 'Mobile',
          selector: row => row.phone_number,
          sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.user_name,
            sortable: true,
        },
        {
            name: 'User Message',
            selector: row => row.message_text,
            sortable: true,
            width: '300px',
            cell: row => (
                <>
                    <div
                        data-tooltip-id={`tooltip-${row.message_text}`}
                        data-tooltip-content={row.message_text}
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: '280px',
                        }}
                    >
                        {row.message_text}
                    </div>
                    <Tooltip
                        id={`tooltip-${row.message_text}`}
                        style={{
                            maxWidth: '400px',  // Adjust this as needed
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        }}
                        place="top" // You can change the position as needed
                    />
                </>
            )
        },
        {
            name: 'Admin Reply',
            selector: row => row.admin_reply_text,
            sortable: true,
            width: '200px',
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
    ];

    useEffect(() => { 
        get(`get_user_queries`)
        .then((res) => {
            console.log("xecue");
            setuserQData(res.data.data.user_queries_list)

        })
        .catch((error) => {
          if (error.response) {
          console.log("errr ");
          }
        });
     }, []);

    return (
        <div>
            <Sidebar isSidebarOpen={isSidebarOpen} />
                <section className="home-section">
                    <Header toggleSidebar={toggleSidebar} />
                        <div className="project-task">
                            <div class="inside-box p-4">
                                {/* <h4>User Queries</h4> */}
                                <DataTable
                                    columns={columns}
                                    data={userQData}
                                    pagination
                                    className="table-style"
                                />
                            </div>
                        </div>
            </section>
        </div>
    )
}

export default UserQueries;
